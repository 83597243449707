import * as React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import DefaultRecordingImage from '../DefaultRecordingImage';
import { cssClampValue, secondsToHhMmSs } from '../../utils/common';
import useAudioPlayer from '../AudioPlayer/useAudioPlayer';
import AudioPlayerContext from '../AudioPlayer/AudioPlayerContext';
import VolumeControl from '../AudioPlayer/VolumeControl';
import PlayPauseButton from '../AudioPlayer/PlayPauseButton';
import SliderControl from '../SliderControl';
import { PlaybackItem } from '../../types';
import LogTimePlayed from '../AudioPlayer/LogTimePlayed';
import { embedTrackSize } from '../EmbedTrackModal/embed-track';
import { siteUrl } from '../../../config';
import EllipsisIcon from '../../images/ellipsis.inline.svg';
import { PlainButton } from '../styled-components';
import Menu from './Menu';
import BecomeAMemberPopup from './BecomeAMemberPopup';

const standard = embedTrackSize.standard.value;

const Wrapper = styled.div`
  --standard-padding: 30px;
  --compact-padding: 20px;
  position: relative;
  overflow: hidden;
`;

const AudioWrapper = styled.div<{
  $theme: string;
  $size: number;
}>`
  display: flex;
  height: ${({ $size }) => `${$size}px`};
  padding: ${({ $size }) =>
    $size === standard ? 'var(--standard-padding)' : 'var(--compact-padding)'};
  background-color: ${({ $theme }) =>
    $theme === 'yellow' ? 'var(--earth-fm--color--yellow)' : 'var(--earth-fm--color--green)'};
  color: ${({ $theme }) => ($theme === 'yellow' ? 'var(--earth-fm--color--green)' : '#fff')};
  border-radius: ${cssClampValue(5, 10)};
  align-items: center;
  flex-direction: ${({ $size }) => ($size === standard ? 'column' : 'row')};
  position: relative;
  overflow: hidden;
  gap: 16px;

  @media (min-width: 560px) {
    flex-direction: ${({ $size }) => $size === standard && 'row'};
  }
`;

const ImageWrapper = styled.div<{ $size: number }>`
  width: ${({ $size }) =>
    $size === standard ? cssClampValue(160, 220, 480) : cssClampValue(85, 120, 480)};
  height: ${({ $size }) =>
    $size === standard ? cssClampValue(160, 220, 480) : cssClampValue(85, 120, 480)};
  overflow: hidden;
  flex-shrink: 0;

  &:first-child {
    margin-top: ${({ $size }) =>
      $size === standard ? '20px' : '0'}; // override the margin-top in Modal
  }

  img {
    border-radius: ${cssClampValue(5, 10)};
    aspect-ratio: 1/1;
  }

  @media (min-width: 480px) {
    margin-right: ${({ $size }) => ($size === standard ? '0' : '15px')};

    &:first-child {
      margin-top: 0; // override the margin-top in Modal
    }
  }

  @media (min-width: 560px) {
    width: ${({ $size }) =>
      $size === standard ? cssClampValue(220, 280) : cssClampValue(120, 150)};
    height: ${({ $size }) =>
      $size === standard ? cssClampValue(220, 280) : cssClampValue(120, 150)};
    margin-right: 15px;
  }

  img {
    height: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  min-width: 1px; // fix text overflow issue
  color: inherit;
  flex: 1;
`;

const TitleWrapper = styled.div<{
  $size: number;
}>`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${({ $size }) => ($size === standard ? 'center' : 'left')};

  > * {
    display: inline-block;
    font-size: ${cssClampValue(16, 20)};
    margin: 0 0 2px;
  }

  @media (min-width: 560px) {
    text-align: left;
  }
`;

const Title = styled.div<{ $size: number }>`
  font-weight: 700;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
  }

  @media (min-width: 480px) {
    width: auto;
    white-space: unset;
    overflow: visible;
    text-overflow: unset;
  }
`;

const Recordist = styled.div`
  a {
    text-decoration: none;
  }

  &:before {
    content: ' ·';
    font-weight: 700;
    margin: 0 4px;
  }
`;

const Controls = styled.div<{ $size: number; $isPreview: boolean }>`
  display: flex;
  margin-top: 0;
  align-items: center;
  gap: 16px;
  justify-content: ${({ $size }) => ($size === standard ? 'center' : 'flex-start')};

  @media (min-width: 560px) {
    margin-top: 12px;
    justify-content: flex-end;
    gap: 16px;
  }

  @media (min-width: 768px) {
    gap: ${({ $isPreview }) => ($isPreview ? '16px' : '46px')};
  }
`;

const PlayerTrack = styled(SliderControl)<{ $theme?: string; $size?: number }>`
  --track-height: 5px;
  --track-fill-color: ${({ $theme }) =>
    $theme === 'yellow' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--yellow)'};
  --thumb-size: 5px;
  --thumb-opacity: 0;
  --thumb-color: ${({ $theme }) =>
    $theme === 'yellow' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--yellow)'};
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;

  @media (hover: hover) {
    &:hover {
      --track-height: 7px;
      --thumb-size: 14px;
      --thumb-color: ${({ $theme }) =>
        $theme === 'yellow' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--yellow)'};
      --thumb-opacity: 1;
    }
  }

  @media (min-width: 768px) {
    --track-height: 7px;
    --thumb-size: 14px;
    --thumb-opacity: 1;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    margin-bottom: 20px;
  }
`;

const StyledVolumeControl = styled(VolumeControl)`
  display: none;

  > div {
    --track-height: 5px;
    --thumb-size: 5px;
  }

  @media (min-width: 400px) {
    display: flex;
    width: 92px;
  }

  @media (min-width: 560px) {
    display: flex;
    width: 110px;
  }
`;

const ControlGroup = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 560px) {
    flex: 1;
  }
`;

const ControlRow = styled.div<{ $isPreview: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: 560px) {
    gap: 0;
  }

  ${({ $isPreview }) =>
    $isPreview &&
    css`
      * > div {
        display: none;
      }
    `}
`;

const PlayerTime = styled.div`
  font-size: 14px;
  line-height: 1;
  color: inherit;
  opacity: 0.8;

  span:nth-of-type(2) {
    display: none;
  }
`;

const StyledPlayPauseButton = styled(PlayPauseButton)`
  @media (min-width: 560px) {
    width: 42px;
    height: 42px;

    svg {
      transform: scale(1.4);
    }
  }

  @media (min-width: 768px) {
    width: 50px;
    height: 50px;

    svg {
      transform: scale(1.44);
    }

    span {
      width: 4px;
    }
  }
`;

const Logo = styled.a<{ $size: number }>`
  display: flex;
  width: ${({ $size }) => ($size === standard ? '79px' : '80px')};
  height: 16px;
  position: absolute;
  top: ${({ $size }) =>
    $size === standard ? 'calc(var(--standard-padding) / 2)' : 'var(--compact-padding)'};
  right: ${({ $size }) =>
    $size === standard ? 'calc(var(--standard-padding) / 2)' : 'var(--compact-padding)'};

  @media (min-width: 560px) {
    width: ${({ $size }) => ($size === standard ? '90px' : '80px')};
    height: ${({ $size }) => ($size === standard ? '18px' : '16px')};
    top: ${({ $size }) =>
      $size && `calc(var(--${$size === standard ? 'standard' : 'compact'}-padding))`};
    right: ${({ $size }) =>
      $size && `calc(var(--${$size === standard ? 'standard' : 'compact'}-padding))`};
  }
`;

const MenuButton = styled(PlainButton)`
  color: inherit;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

type Props = {
  data: PlaybackItem;
  trackTheme: string;
  trackSize: number;
  className?: string;
  isPreview?: boolean;
  isMember: boolean;
  memberId?: string | null;
};

export default function EmbeddableAudioPlayer({
  data,
  trackTheme,
  trackSize,
  className = '',
  isPreview = false,
  isMember = false,
  memberId = null,
}: Props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const audioPlayer = useAudioPlayer({ syncInLocalStorage: false });
  const { currentTime } = audioPlayer;

  React.useEffect(() => {
    if (!isMember) {
      // check currentTime of the track and if the user is reaching to the limit (60 seconds) shows the popup
      setIsPopupOpen(Math.round(audioPlayer.currentTime) > 50);

      // after 10 seconds, pause the player
      if (Math.round(audioPlayer.currentTime) > 60) {
        audioPlayer.pause();
      }

      return;
    }

    // if the user is paid member, show the popup when the track has reached the end
    if (
      isMember &&
      audioPlayer.currentItem?.duration &&
      audioPlayer.currentItem.duration - audioPlayer.currentTime < 10
    ) {
      setIsPopupOpen(true);
      audioPlayer.pause();
    }
  }, [audioPlayer, audioPlayer.currentTime, currentTime, isMember]);

  const handlePopupClose = React.useCallback(() => {
    audioPlayer.reset();
    setIsPopupOpen(false);
  }, [audioPlayer]);

  const playbackItem: PlaybackItem | null = React.useMemo(() => {
    if (!data.id || !data.title || !data.sourceUrl || !data.duration) {
      return null;
    }

    return {
      id: data.id,
      title: data.title,
      userFriendlyTitle: data.userFriendlyTitle,
      sourceUrl: data.sourceUrl,
      coverImg: data.coverImg,
      duration: data.duration,
      uri: data.uri,
      recordist: data.recordist,
      recordists: data.recordists,
      isLivestream: data.isLivestream,
    };
  }, [data]);

  const { currentItem, loadPlayback } = React.useMemo(
    () => ({
      currentItem: audioPlayer.currentItem,
      loadPlayback: audioPlayer.loadPlayback,
    }),
    [audioPlayer.currentItem, audioPlayer.loadPlayback],
  );

  React.useEffect(() => {
    if (!playbackItem || (currentItem && currentItem.id === playbackItem.id)) {
      return;
    }

    loadPlayback({ item: playbackItem, eventSource: 'in_embed_track_player' });
  }, [currentItem, playbackItem, loadPlayback]);

  const image = React.useMemo(() => {
    if (data.coverImg && typeof data.coverImg === 'string') {
      return <img src={data.coverImg} alt={data.title} />;
    }

    if (data.coverImg && typeof data.coverImg !== 'string') {
      const imageSrc = getImage(data.coverImg) || null;
      if (!imageSrc) {
        return <DefaultRecordingImage alt="" />;
      }
      return <GatsbyImage image={imageSrc} alt={data.title} />;
    }

    return <DefaultRecordingImage alt="" />;
  }, [data.coverImg, data.title]);

  const toggleMenu = React.useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsCopied(false);
    setMenuOpen(false);
  }, []);

  return (
    <AudioPlayerContext.Provider value={audioPlayer}>
      {audioPlayer?.currentItem && (
        <LogTimePlayed
          currentTime={audioPlayer.currentTime}
          duration={audioPlayer.currentItem.duration}
          recordingId={audioPlayer.currentItem.id}
          playerId="in_embed_track_player"
        />
      )}
      <Wrapper>
        <AudioWrapper className={className || ''} $theme={trackTheme} $size={trackSize}>
          <ImageWrapper $size={trackSize}>
            <a href={`${siteUrl}${data.uri}`} rel="noopener noreferrer" target="_blank">
              {image}
            </a>
          </ImageWrapper>
          <Content>
            <Logo
              href={siteUrl}
              title="Earth.fm homepage"
              target="_blank"
              rel="noopener noreferrer"
              $size={trackSize}
              aria-haspopup="true"
            >
              {trackTheme === 'yellow' ? (
                <StaticImage
                  src="./icons/earth-fm-logo.png"
                  alt="earth.fm logo"
                  placeholder="blurred"
                />
              ) : (
                <StaticImage
                  src="./icons/earth-fm-logo-white.png"
                  alt="earth.fm logo"
                  placeholder="blurred"
                />
              )}
            </Logo>
            <TitleWrapper $size={trackSize}>
              <Title $size={trackSize}>
                <a href={`${siteUrl}${data.uri}`} rel="noopener noreferrer" target="_blank">
                  {data.userFriendlyTitle || data.title}
                </a>
              </Title>
              <Recordist>
                {data.recordists ? (
                  data.recordists.map((r, i) => (
                    <>
                      <a
                        href={`${siteUrl}${r.uri}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        key={r.id}
                      >
                        {r.title}
                      </a>
                      {data.recordists.length - 1 !== i && ', '}
                    </>
                  ))
                ) : (
                  <a
                    href={`${siteUrl}${data.recordistUri}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {data.recordist}
                  </a>
                )}
              </Recordist>
            </TitleWrapper>
            <Controls $size={trackSize} $isPreview={isPreview}>
              <StyledPlayPauseButton />
              <ControlGroup>
                <PlayerTrack
                  inputId="PlayerTrack"
                  value={audioPlayer.currentTime}
                  min={0}
                  max={audioPlayer.currentItem?.duration || 0}
                  step={0.1}
                  onChange={audioPlayer.seekTo}
                  $theme={trackTheme}
                  $size={trackSize}
                />
                <ControlRow $isPreview={isPreview}>
                  <div>
                    <StyledVolumeControl />
                  </div>
                  <PlayerTime>
                    {secondsToHhMmSs(audioPlayer.currentTime)}
                    <span> / </span>
                    {secondsToHhMmSs(audioPlayer.currentItem?.duration || 0)}
                  </PlayerTime>
                </ControlRow>
              </ControlGroup>
            </Controls>
            <MenuButton onClick={toggleMenu}>
              <EllipsisIcon />
            </MenuButton>
          </Content>
          {isPopupOpen && (
            <BecomeAMemberPopup
              trackSize={trackSize}
              isPopupOpen={isPopupOpen}
              status={isMember ? 'default' : 'becomeAMember'}
              handleClose={handlePopupClose}
            />
          )}
        </AudioWrapper>
        <Menu
          recordingId={data.id}
          recordingUri={data.uri}
          trackTheme={trackTheme}
          trackSize={trackSize}
          menuOpen={menuOpen}
          handleClose={handleClose}
          isCopied={isCopied}
          setIsCopied={setIsCopied}
          isMember={isMember}
          memberId={memberId}
        />
      </Wrapper>
    </AudioPlayerContext.Provider>
  );
}
